
  import { defineComponent, onBeforeMount, reactive, toRefs, computed } from 'vue';
  import { getImitateUserPage, getTenantId, getMackChange } from '@/api/imitateUser';
  import { getAllUsers, getAdministratorsList } from '@/api/userinfo';
  import { ElMessageBox, ElMessage } from 'element-plus';
  import Cookies from 'js-cookie';
  import { getDomain } from '@/utils/urlHandler';
  import { IndexStateType } from './indexType';

  export default defineComponent({
    name: 'imitateUser',
    setup() {
      const state = reactive<IndexStateType>({
        searchForm: {
          tenantId: '',
          userName: '',
          phoneNum: '',
          enable: '',
        },
        addForm: null,
        tableData: [],
        selectData: [],
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        switchId: sessionStorage.getItem('switchId') || '',
      });
      const domain = getDomain(window.location.href);

      const getData = async (params: any) => {
        const res: any = await getImitateUserPage(params);
        if (res.code === 0) {
          state.tableData = res.data.content;
          state.totalCount = res.data.content.length;
        }
      };
      const updateData = () => {
        const params = {
          pageNumber: state.pageNumber - 1,
          pageSize: state.pageSize,
        };
        console.log(state.searchForm);
        Object.keys(state.searchForm).forEach((key) => {
          if (state.searchForm[key]) {
            params[key] = state.searchForm[key];
          }
        });
        console.log(params);
        getData(params);
      };

      const handleReset = (name: string) => {
        const form: any = state[name];
        form.resetFields();
        state.searchForm.tenantId = state.selectData[0]?.key;
      };
      const handleSwitchChange = async (value: any, row: any) => {
        if (value) {
          state.switchId = row.userId;
          sessionStorage.setItem('switchId', row.userId);
          const res = await getMackChange({
            userId: row.userId,
          });
          Cookies.set('token', res.data, { domain });
        } else {
          const token = sessionStorage.getItem('token');
          state.switchId = '';
          sessionStorage.setItem('switchId', '');
          Cookies.set('token', token || '', { domain });
        }
      };
      const getSelectData = async () => {
        const res = await getTenantId();
        state.selectData = res.data;
        state.searchForm.tenantId = res.data[0].key;
        const params = {
          pageNumber: 0,
          pageSize: state.pageSize,
          tenantId: res.data[0].key,
        };
        await getData(params);
      };

      onBeforeMount(() => {
        getSelectData();
      });
      return {
        ...toRefs(state),
        updateData,
        getData,
        handleReset,
        handleSwitchChange,
      };
    },
  });
