import $http from '@/utils/axios/axiosUtils';

export function getMackChange(params: any) {
  return $http({
    url: `/user/simulate/${params?.userId}`,
    method: 'post',
    data: params,
  });
}
export function getTenantId() {
  return $http({
    url: `/tenant/list`,
    method: 'post',
    data: {
      value: '',
    },
  });
}
// 获取系统分页
export function getImitateUserPage(params: any) {
  return $http({
    url: `/user/tenantUser/inquiry`,
    method: 'post',
    data: params,
  });
}
